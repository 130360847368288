"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userEnvironmentSchema = exports.tokenUserSchema = exports.userPatchSchema = exports.userAdminPatchSchema = exports.userAdminInputSchema = exports.userSchema = void 0;
const zod_1 = require("zod");
const services_1 = require("../services");
exports.userSchema = zod_1.z.object({
    ...services_1.standardAttributes,
    nameFirst: zod_1.z.string(),
    nameLast: zod_1.z.string(),
    isAdmin: zod_1.z.boolean(),
    email: zod_1.z.string().email().nullable(),
    address: zod_1.z.string().nullable(),
    birthdate: zod_1.z.date().nullable(),
    imageId: zod_1.z.string().nullable(),
    phone: zod_1.z
        .string()
        .regex(/^\+41\s\d{3}\s\d{3}\s\d{3}$/)
        .nullable(),
    mobile: zod_1.z
        .string()
        .regex(/^\+41\s\d{3}\s\d{3}\s\d{3}$/)
        .nullable(),
    iban: zod_1.z
        .string()
        .regex(/^CH\d{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d$/)
        .nullable(),
    twitter: zod_1.z.string().nullable(),
    xing: zod_1.z.string().nullable(),
    linkedin: zod_1.z.string().nullable(),
    facebook: zod_1.z.string().nullable(),
    instagram: zod_1.z.string().nullable(),
    website: zod_1.z.string().url().nullable(),
    invisibilityTill: zod_1.z.date().nullable(),
});
(0, services_1.checkSchema)(exports.userSchema);
exports.userAdminInputSchema = exports.userSchema.omit({
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
});
exports.userAdminPatchSchema = exports.userAdminInputSchema.omit({
    id: true,
});
exports.userPatchSchema = exports.userSchema.omit({
    id: true,
    createdAt: true,
    updatedAt: true,
    deletedAt: true,
    isAdmin: true,
    email: true,
    invisibilityTill: true,
});
exports.tokenUserSchema = exports.userSchema.pick({
    id: true,
    nameFirst: true,
    nameLast: true,
    isAdmin: true,
    imageId: true,
});
exports.userEnvironmentSchema = zod_1.z.object({
    id: zod_1.z.string().uuid(),
    user: exports.userSchema,
});
