import React from "react";
import { Global } from "@emotion/react";
import { globalStyles } from "client-lib";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "./apollo";
import { LoginPage, AuthProvider, AuthRoute } from "./features/auth";
import { DashboardPage } from "./features/dashboard";
import { UserRouter } from "./features/user";
import { FileRouter } from "./features/file";
import { IconRouter } from "./features/icon";
import { CategoryRouter } from "./features/category";
import { ClubRouter } from "./features/club/club-router";
import { OrdersRouter } from "./features/order/orders-router";

export function App() {
  return (
    <BrowserRouter>
      <ApolloProvider>
        <AuthProvider>
          <Global styles={globalStyles} />
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <AuthRoute path="/" exact component={DashboardPage} />
            <AuthRoute path="/users" component={UserRouter} />
            <AuthRoute path="/icons" component={IconRouter} />
            <AuthRoute path="/files" component={FileRouter} />
            <AuthRoute path="/categories" component={CategoryRouter} />
            <AuthRoute path="/clubs" component={ClubRouter} />
            <AuthRoute path="/orders" component={OrdersRouter} />
          </Switch>
        </AuthProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
}
