import React, { useCallback } from "react";
import { omit } from "lodash";
import { useMutation } from "@apollo/client";
import { UserAdminInput, User } from "shared";
import { ParseError } from "src/lib";
import { Modal } from "client-lib";
import { UserForm } from "./user-form";
import {
  AdminUpdateUser,
  AdminUpdateUserVars,
  AdminUpdateUserData,
} from "../queries";

interface Props {
  user: User;
  onCloseRequest: () => any;
  onUpdated?: () => any;
}

export function UpdateUserModal({ user, onCloseRequest, onUpdated }: Props) {
  const [update, { loading, error }] = useMutation<
    AdminUpdateUserData,
    AdminUpdateUserVars
  >(AdminUpdateUser);

  const handleSubmit = useCallback(
    async (input: UserAdminInput) => {
      await update({
        variables: {
          id: user.id,
          patch: omit(input, "id"),
        },
      });
      onUpdated?.();
      onCloseRequest();
    },
    [update, onCloseRequest, user.id, onUpdated]
  );

  return (
    <Modal onCloseRequest={onCloseRequest} title="Edit user" minWidth={500}>
      {error ? <ParseError error={error} /> : null}
      <UserForm
        initialValues={omit(
          user,
          "__typename",
          "createdAt",
          "updatedAt",
          "deletedAt"
        )}
        disabled={loading}
        onSubmit={handleSubmit}
        submitTitle="Save"
      />
    </Modal>
  );
}
